import Logo from '@/components/Logo'
import ProgressBar from '@/components/ProgressBar'
import { Actions, Events } from '@/enums/eventos'
import { trackEvent } from '@/lib/tracking'
import { ArrowLeft, X } from '@repo/icons'
import Link from 'next/link'
import { useEffect, useState } from 'react'

export interface StepHeaderProps {
  showPreviousPageButton?: boolean
  showCloseButton?: boolean
  progress?: number
  previousLink?: string
  onClickPreviousLink?: () => void
}

const StepHeader: React.FC<StepHeaderProps> = ({
  previousLink,
  progress,
  showCloseButton = true,
  showPreviousPageButton = false,
  onClickPreviousLink,
}) => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false)

  const previousPageClick = () => {
    trackEvent(Events.HEADER_AGENDAMENTO, {
      action: Actions.CLICKED,
      label: 'clicou em voltar',
      value: previousLink,
    })

    if (onClickPreviousLink) {
      onClickPreviousLink()
    }
  }

  const closeBtnClick = () => {
    trackEvent(Events.HEADER_AGENDAMENTO, {
      action: Actions.CLICKED,
      label: 'clicou em fechar',
      value: '/',
    })
  }

  const handleScroll = () => {
    if (document.documentElement.scrollTop > 0) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }

  useEffect(() => {
    window.onscroll = () => handleScroll()
  }, [])

  return (
    <header className="mb-6 h-[81px] flex flex-col-reverse relative w-full">
      <div
        className={`flex items-center h-[73px] left-0 top-0 w-inherit bg-white z-50 transition-shadow duration-200 ${
          isScrolled ? 'shadow-sm fixed left-0' : 'absolute'
        }`}
      >
        <div className={`mx-auto w-inherit ${isScrolled ? 'lg:pl-[20px] lg:pr-3' : ''}`}>
          <div className="container flex justify-between items-center my-0 mx-auto">
            <div
              className={showPreviousPageButton ? 'visible' : 'invisible'}
              onClick={() => previousPageClick()}
            >
              <Link href={previousLink || '/'}>
                <ArrowLeft size={24} className="fill-neutral-500" />
              </Link>
            </div>

            <div
              id="saludia-logo"
              onClick={() =>
                trackEvent(Events.HEADER_AGENDAMENTO, {
                  action: Actions.CLICKED,
                  label: 'clicou no logo do saludia',
                  value: '/',
                })
              }
            >
              <Link href="/">
                <Logo />
              </Link>
            </div>

            <div
              className={showCloseButton ? 'visible' : 'invisible'}
              onClick={() => closeBtnClick()}
            >
              <Link href={'/'}>
                <X size={24} className="fill-neutral-500" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {progress && <ProgressBar progress={progress} />}
    </header>
  )
}

StepHeader.displayName = 'StepHeader'

export default StepHeader
