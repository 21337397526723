export interface ProgressBar {
  progress: number
}

const ProgressBar: React.FC<ProgressBar> = ({ progress }) => {
  const styles = {
    progress: {
      width: progress + '%',
    },
  } as const
  return (
    <div className="w-screen h-2 bg-neutral-100 absolute left-0">
      <div
        style={styles.progress}
        className={`transition-all duration-200 bg-primary-400 h-full rounded-r-full`}
      ></div>
    </div>
  )
}

ProgressBar.displayName = 'ProgressBar'

export default ProgressBar
