import Divider from '@/components/Divider'
import StatusCodePage from '@/components/StatusCodePage'
import StepHeader from '@/components/StepHeader'
import { HttpStatusCode } from '@/enums/http-status-code'

export default function Custom500() {
  return (
    <div className="container mx-auto flex min-h-screen flex-col justify-start">
      <StepHeader showPreviousPageButton={false} showCloseButton={false} />

      <Divider />

      <StatusCodePage
        code={HttpStatusCode.NotFound}
        title={'Não conseguimos encontrar a página que você está buscando.'}
      />
    </div>
  )
}
