import { HttpStatusCode } from '@/enums/http-status-code'
import { subdomainEnv } from '@/operadoras-settings'
import { CpsLink } from 'corpus'
import Link from 'next/link'
import { useRouter } from 'next/router'

export interface StatusCodePageProps {
  code: HttpStatusCode.NotFound | HttpStatusCode.InternalServerError
  title: string
}

const isPreviewEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
const ACROPOLIS_URL = isPreviewEnvironment
  ? process.env.NEXT_PUBLIC_VERCEL_URL
  : process.env.NEXT_PUBLIC_ACROPOLIS_URL

const StatusCodePage: React.FC<StatusCodePageProps> = ({ code, title }) => {
  const router = useRouter()
  let url = ''
  const subdomain = router.query.subdomain
  if (!subdomainEnv[subdomain as string]) {
    url = ACROPOLIS_URL as string
  }

  return (
    <main className="container mt-14">
      <div className="flex flex-col gap gap-y-14">
        <h1 className="text-primary-400 text-7xl font-semibold">Oops</h1>
        <div>
          <h2 className="text-neutral-600 font-semibold text-2xl">{title}</h2>
          <p className="text-neutral-500 font-normal text-xl">Código de erro: {code}</p>
        </div>
        <div>
          <p className="text-neutral-600 font-medium text-xl mb-6">
            Aqui estão algumas páginas úteis para você começar:
          </p>
          {code === HttpStatusCode.NotFound ? (
            <div className="flex flex-col gap gap-y-2">
              <CpsLink as={Link} href={url + '/'} color="primary">
                Voltar para página inicial
              </CpsLink>
              <CpsLink as={Link} href={url + '/consulta'} color="primary">
                Agendar consulta presencial
              </CpsLink>
              <CpsLink as={Link} href={url + '/teleconsulta'} color="primary">
                Agendar teleconsulta
              </CpsLink>
            </div>
          ) : (
            <>
              <CpsLink href="#" onClick={() => router.back()} color="primary">
                Voltar para página anterior
              </CpsLink>
            </>
          )}
        </div>
      </div>
    </main>
  )
}

export default StatusCodePage
